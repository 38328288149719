import React from 'react';
import ReactDOM from 'react-dom';
import { createSounds, SoundsProvider, Footer,Header,ThemeProvider, createTheme, Arwes, Heading, Project, Words, List, Link } from 'arwes';
import './index.css';

const sounds = {
    shared: { volume: 1 },
    players: {
        information: { sound: { src: ['/static/sound/information.mp3'] } },
        ask: { sound: { src: ['/static/sound/ask.mp3'] } },
        warning: { sound: { src: ['/static/sound/warning.mp3'] } },
        error: { sound: { src: ['/static/sound/error.mp3'] } },
    },
};

const App = () => (
  <ThemeProvider theme={createTheme()}>
    <SoundsProvider sounds={createSounds(sounds)}>
    <Arwes animate background='/static/img/background.jpg' pattern='/static/img/glow.png'>
    <Header animate>
                <Heading node='h1' style={{  margin: '0 0 0 50px' }}>SysArt</Heading>
    </Header>
    <Project animate header='Better infrastructure' style={{ margin: '40px auto', width: '50%' }}>
                {anim => (
                    <div style={{  margin: '0 0 0 50px' }} >
                    <Words animate show={anim.entered}>
                        Struggling with ?
                    </Words>
                    <List node='ul' style={{ marginLeft: '10px' }} >
                      <li><Link href='#'>Continuous delivery</Link></li>
                      <li><Link href='#'>Continuous integration</Link></li>
                      <li><Link href='#'>Microservices</Link></li>
                      <li><Link href='#'>Containers</Link></li>
                      <li><Link href='#'>Automated Builds</Link></li>
                      <li><Link href='#'>Docker</Link></li>
                      <li><Link href='#'>Orchestration</Link></li>
                      <li><Link href='#'>Kubernetes</Link></li>
                      <li><Link href='#'>IaC</Link></li>
                      <li><Link href='#'>Ansible</Link></li>
                      <li><Link href='#'>IaC</Link></li>
                      <li><Link href='#'>Cloud</Link></li>
                      <li><Link href='#'>DevOps</Link></li>
                      <li><Link href='#'>Infrastructure-as-a-Service</Link></li>
                      <li><Link href='#'>Tests Automation</Link></li>
                      </List>
                    <Words animate show={anim.entered}>
                        let us help YOU 
                    </Words>
                    </div>
                )}
            </Project>
    <Footer animate>
                <p style={{ padding: '25px 20px 0px 0px', textAlign: 'right' }}>SysArt s. r. o. , Kopčianska 10, 851 01 Bratislava, +421 907 22 44 96, info@sysart.tech</p>
            </Footer>
    </Arwes>
  </SoundsProvider>
  </ThemeProvider>
);

ReactDOM.render(<App />, document.querySelector('#root'));
